<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <v-icon x-large>diversity_3</v-icon>
        <h1 class="ms-3">קבוצות</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="text-center"
          :headers="headers"
          :items="items"
          mobile-breakpoint="0"
        >
          <template v-slot:item="{ item }">
            <tr @click="edit_row(item)">
              <td>
                {{ item.name }}
              </td>
              <td>{{ item.created_at }}</td>
            </tr>

            <tr v-if="item.form" class="grey lighten-3">
              <td colspan="10">
                <v-form :ref="`form_${item.id}`">
                  <v-row class="basic mt-2 mb-4 pt-3">
                    <v-col class="py-0">
                      <v-text-field
                        @keydown="input_change(item, 'name_exist')"
                        v-model="item.name"
                        background-color="white"
                        outlined
                        dense
                        :rules="[
                          (v) => !!v || 'שדה חובה',
                          !item.name_exist || '',
                        ]"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          שם מותג
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12 text-start">
                      <v-btn
                        :color="
                          allPermissionsSelected(item.permissions)
                            ? 'error'
                            : 'green darken-4'
                        "
                        class="white--text mb-5"
                        @click="select_all(item)"
                      >
                        {{ allPermissionsSelected ? "נקה הכל " : "בחירת הכל " }}
                      </v-btn>
                      <v-row class="pa-0">
                        <v-col
                          cols="4"
                          v-for="(group, i) in $store.state.companyPermissions
                            .data"
                          :key="i"
                        >
                          <v-card class="elevation-0" outlined>
                            <v-card-title
                              flat
                              class="d-flex align-center justify-space-between"
                            >
                              <h5>
                                {{ permissionTran[Object.keys(group)[0]] }}
                              </h5>

                              <v-checkbox
                                x-larage
                                class="font-weight-bold"
                                label="הכל"
                                :input-value="groupSelected(item, group)"
                                @click="selectGroup(group, item)"
                              ></v-checkbox>
                            </v-card-title>

                            <v-card-text>
                              <div
                                v-for="(permission, x) in group[
                                  Object.keys(group)[0]
                                ]"
                                :key="x"
                              >
                                <v-checkbox
                                  @click="selectPermission(item, permission)"
                                  :input-value="
                                    item.permissions.includes(permission)
                                  "
                                  :label="permissionTran[permission]"
                                ></v-checkbox>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0 d-flex justify-space-between">
                      <v-btn
                        depressed
                        height="40"
                        width="59%"
                        color="black white--text"
                        class="me-2"
                        :loading="item.save_loader"
                        :disabled="item.save_loader"
                        @click="save_update(item)"
                        v-if="$store.getters.permission('brands edit')"
                        >שמירה</v-btn
                      >
                      <v-btn
                        v-if="$store.getters.permission('brands delete')"
                        @click="delete_row(item.id)"
                        depressed
                        height="40"
                        width="35%"
                        color="white "
                      >
                        <v-icon color="black">delete_sweep </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DeleteDialog
      @delete_done="$emit('update_data')"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
import permissionTran from "@/data/permissionTran";
export default {
  name: "Table",
  props: ["data_loaded", "items"],
  data() {
    return {
      delete_obj: {
        delete_prop: false,
        delete_url: "groups",
        title: "מחק קבוצה",
        id: null,
      },
      permissionTran: permissionTran,
      headers: [
        {
          value: "name",
          text: "שם קבוצה",
          align: "center",
        },
        {
          value: "created_at",
          text: "תאריך הוספה",
          align: "center",
        },
      ],
    };
  },
  components: {
    DeleteDialog,
  },
  computed: {
    allPermissionsSelected: (app) => (permissions) => {
      return permissions.length == app.totalPermissions.length;
    },
    groupSelected: (app) => (item, group) => {
      let arr = Object.values(group)[0];
      return (
        item.permissions.filter((e) => arr.includes(e)).length == arr.length
      );
    },
    totalPermissions() {
      let totalItems = [];

      this.$store.state.companyPermissions.data.forEach((item) => {
        Object.values(item).forEach((array) => {
          totalItems = [...totalItems, ...array];
        });
      });
      return totalItems;
    },
  },

  methods: {
    input_change(item, type) {
      this.$set(item, `${type}`, false);
    },
    edit_row(item) {
      this.$emit("close_form");
      this.items.forEach((e) => {
        e.form = false;
      });
      item.form = true;
    },
    save_update(item) {
      if (
        this.$refs[`form_${item.id}`].validate() &&
        !item.save_loader &&
        this.$store.getters.permission("brands edit")
      ) {
        item.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `groups`,
              method: "post",
            },
            data: item,
          })
          .then((res) => {
            item.save_loader = false;
            this.$emit("update_data");
          })
          .catch((err) => {
            item.save_loader = false;
            let errors = err.response.data.data;
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(item, `${e}_exist`, true);
                }
              });
            });
          });
      }
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },

    select_all(item) {
      if (!this.allPermissionsSelected(item.permissions)) {
        item.permissions = Object.assign([], this.totalPermissions);
      } else {
        item.permissions = [];
      }
    },
    selectGroup(group, item) {
      let arr = Object.values(group)[0];

      if (this.groupSelected(item, group)) {
        item.permissions = item.permissions.filter((e) => !arr.includes(e));
      } else {
        item.permissions = [...item.permissions, ...arr];
      }
    },
    selectPermission(item, permission) {
      let permissionIndex = item.permissions.findIndex((e) => e == permission);
      if (permissionIndex > -1) {
        item.permissions.splice(permissionIndex, 1);
      } else {
        item.permissions.push(permission);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.basic {
  .col:not(.col-12, .col-4) {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
