<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <Table
          ref="table"
          @close_form="close_form()"
          :data_loaded="data_loaded"
          :items="groups"
          @data_loaded="data_loaded = true"
          @update_data="get_data()"
        ></Table>

        <v-btn
          elevation="8"
          class="mt-5"
          v-if="
            !form && data_loaded && $store.getters.permission('brands create')
          "
          @click="form = !form"
          fab
          small
        >
          <v-icon>add</v-icon>
        </v-btn>
        <Form
          @update_table="update_table()"
          @close_form="form = !form"
          v-if="form && $store.getters.permission('brands create')"
        ></Form>
      </div>
    </div>
  </v-container>
</template>

<script>
import Table from "@/components/groups/table";
import Form from "@/components/groups/form";
export default {
  name: "employees_page",
  data() {
    return {
      form: false,
      data_loaded: false,
      groups: [],
    };
  },
  components: {
    Table,
    Form,
  },

  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "groups",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.groups = Object.assign([], res.data.data);
        });
    },
    update_table() {
      this.get_data();
    },
    close_form() {
      if (this.$refs.form) {
        this.$refs.form.close_form();
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
