<template>
  <v-card class="mt-10 mb-10">
    <v-card-title> קבוצה חדשה</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              outlined
              dense
              v-model="item.name"
              @keydown="name_exist = false"
              :rules="[(v) => !!v || 'שדה חובה', !name_exist || 'כבר קיים']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                שם קבוצה
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              :color="allPermissionsSelected ? 'error' : 'green darken-4'"
              class="white--text mb-5"
              @click="select_all()"
            >
              {{ allPermissionsSelected ? "נקה הכל " : "בחירת הכל " }}
            </v-btn>
            <v-row class="pa-0">
              <v-col
                cols="4"
                v-for="(group, i) in $store.state.companyPermissions.data"
                :key="i"
              >
                <v-card class="elevation-0" outlined>
                  <v-card-title
                    flat
                    class="d-flex align-center justify-space-between"
                  >
                    <h3>
                      {{ permissionTran[Object.keys(group)[0]] }}

                    </h3>
                    <v-checkbox
                      x-larage
                      class="font-weight-bold"
                      label="הכל"
                      :input-value="groupSelected(group)"
                      @click="selectGroup(group)"
                    ></v-checkbox>
                  </v-card-title>

                  <v-card-text>
                    <div
                      v-for="(permission, x) in group[Object.keys(group)[0]]"
                      :key="x"
                    >
                      <v-checkbox
                        @click="selectPermission(permission)"
                        :input-value="item.permissions.includes(permission)"
                  :label="permissionTran[permission]"
                      ></v-checkbox>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          @click="save()"
          :loading="save_loader"
          :disabled="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import permissionTran from "@/data/permissionTran";
export default {
  name: "Form",
  data() {
    return {
      save_loader: false,
      name_exist: false,
      item: {
        id: null,
        name: "",
        permissions: [],
      },
      permissionTran: permissionTran,
    };
  },
  computed: {
    title() {
      return this.$route.params.id ? "تعديل الموظف" : "اضافة موظف";
    },
    allPermissionsSelected() {
      return this.item.permissions.length == this.totalPermissions.length;
    },
    groupSelected: (app) => (group) => {
      let arr = Object.values(group)[0];
      return (
        app.item.permissions.filter((e) => arr.includes(e)).length == arr.length
      );
    },
    totalPermissions() {
      let totalItems = [];

      this.$store.state.companyPermissions.data.forEach((item) => {
        Object.values(item).forEach((array) => {
          totalItems = [...totalItems, ...array];
        });
      });
      return totalItems;
    },
  },
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "groups",
              method: "POST",
            },
            data: this.item,
          })
          .then((res) => {
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.errors;

            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this[`${e}_exist`] = true;
                }
              });
            });
          });
      }
    },

    select_all() {
      if (!this.allPermissionsSelected) {
        this.item.permissions = Object.assign([], this.totalPermissions);
      } else {
        this.item.permissions = [];
      }
    },
    selectGroup(group) {
      let arr = Object.values(group)[0];

      if (this.groupSelected(group)) {
        this.item.permissions = this.item.permissions.filter(
          (e) => !arr.includes(e)
        );
      } else {
        this.item.permissions = [...this.item.permissions, ...arr];
      }
    },
    selectPermission(permission) {
      let permissionIndex = this.item.permissions.findInex(
        (e) => e == permission
      );
      console.log(permissionIndex);
      if (permissionIndex > -1) {
        this.item.permissions.splice(permissionIndex, 1);
      } else {
        this.item.permissions.push(permission);
      }
    },
  },
};
</script>
