var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.data_loaded)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(_vm.data_loaded)?_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("diversity_3")]),_c('h1',{staticClass:"ms-3"},[_vm._v("קבוצות")])],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"text-center",attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{on:{"click":function($event){return _vm.edit_row(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(_vm._s(item.created_at))])]),(item.form)?_c('tr',{staticClass:"grey lighten-3"},[_c('td',{attrs:{"colspan":"10"}},[_c('v-form',{ref:`form_${item.id}`},[_c('v-row',{staticClass:"basic mt-2 mb-4 pt-3"},[_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"background-color":"white","outlined":"","dense":"","rules":[
                        (v) => !!v || 'שדה חובה',
                        !item.name_exist || '',
                      ]},on:{"keydown":function($event){return _vm.input_change(item, 'name_exist')}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}},[_c('template',{slot:"label"},[_c('strong',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" שם מותג ")])],2)],1),_c('v-col',{attrs:{"cols":"12 text-start"}},[_c('v-btn',{staticClass:"white--text mb-5",attrs:{"color":_vm.allPermissionsSelected(item.permissions)
                          ? 'error'
                          : 'green darken-4'},on:{"click":function($event){return _vm.select_all(item)}}},[_vm._v(" "+_vm._s(_vm.allPermissionsSelected ? "נקה הכל " : "בחירת הכל ")+" ")]),_c('v-row',{staticClass:"pa-0"},_vm._l((_vm.$store.state.companyPermissions
                          .data),function(group,i){return _c('v-col',{key:i,attrs:{"cols":"4"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between",attrs:{"flat":""}},[_c('h5',[_vm._v(" "+_vm._s(_vm.permissionTran[Object.keys(group)[0]])+" ")]),_c('v-checkbox',{staticClass:"font-weight-bold",attrs:{"x-larage":"","label":"הכל","input-value":_vm.groupSelected(item, group)},on:{"click":function($event){return _vm.selectGroup(group, item)}}})],1),_c('v-card-text',_vm._l((group[
                                Object.keys(group)[0]
                              ]),function(permission,x){return _c('div',{key:x},[_c('v-checkbox',{attrs:{"input-value":item.permissions.includes(permission),"label":_vm.permissionTran[permission]},on:{"click":function($event){return _vm.selectPermission(item, permission)}}})],1)}),0)],1)],1)}),1)],1),_c('v-col',{staticClass:"py-0 d-flex justify-space-between"},[(_vm.$store.getters.permission('brands edit'))?_c('v-btn',{staticClass:"me-2",attrs:{"depressed":"","height":"40","width":"59%","color":"black white--text","loading":item.save_loader,"disabled":item.save_loader},on:{"click":function($event){return _vm.save_update(item)}}},[_vm._v("שמירה")]):_vm._e(),(_vm.$store.getters.permission('brands delete'))?_c('v-btn',{attrs:{"depressed":"","height":"40","width":"35%","color":"white "},on:{"click":function($event){return _vm.delete_row(item.id)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("delete_sweep ")])],1):_vm._e()],1)],1)],1)],1)]):_vm._e()]}}],null,false,3790847463)})],1)],1):_vm._e(),_c('DeleteDialog',{attrs:{"delete_obj":_vm.delete_obj},on:{"delete_done":function($event){return _vm.$emit('update_data')},"close_delete":function($event){_vm.delete_obj.delete_prop = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }